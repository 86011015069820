/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}*/
body {
	padding-top: 0px;
  /*margin-left:500px;
  margin-right:100px;*/
}

.btn-headline-text{
  color:rgb(13, 14, 13);
  font-size: 22px;
  text-decoration: none
}

.btn-light-image-and-link{
  /*color:rgb(13, 14, 13);  */
  text-decoration: none
}

.btn-light-image-and-link:hover{
	/*border-color: #F5F3F9;
	text-decoration: underline !important;
	color:rgb(241, 241, 247);*/
	font-weight : bold ;
}

.btn-light-whole-square{
  /*color:rgb(219, 15, 15);  */
  padding-bottom: 150px;
  height: 550px;
}

/*.btn-primary {
	background-color: #F5F3F9 !important;
	text-decoration: underline !important;
	border-color: #F5F3F9;
	color:black;
	font-weight : bold ;
}*/



/*.btn-light{
  color:rgb(8, 8, 8);
  font-size: 25px;
}*/

.container-fluid{
  margin-left: 0px;
  margin-right: 0px;
}

.img-fluid {
  width: 600px;
  height: 400px;
  float: left;
  margin: 0px;
  vertical-align:top
}



.navbar-brand{
  font-size: 45px;  
}

.div-standingsTableTitle{
  background-color: rgb(248, 247, 245);
  /*margin-left: 200px;
  margin-right: 200px;
  margin-top: 30px;*/
  width: auto;
  max-width: 1000px;
  margin: auto;
  margin-top: 3%;
}

.div-driverStandingsTableClass{
  font-size:83%

}

.div-constructorStandingsTableClass{
  font-size:83%

}

.h3-tableTitle{
  /*margin-left: 30%;
  margin-right: 200px;
  margin-top: 30px;
  padding-left: 10%;*/
  margin-left: auto;
  margin-right: auto;
}

/*
.img-circuit{  
  width: 100%;
  height: 400px;
}
.img-flag{  
  width: 100%;
  height: 180px;
}*/
.div-scheduleEntry{


}


.table-flags-and-events{
  /*table-layout: fixed; */
  
}

/*.div-scheduleTitle{
  margin-right: 50%;
  width:100%
  
}*/

/*.div-fullSchedulePageIncNavbar{
 
}*/

.timezoneSelector-tz{
  width: 360px;
  margin: auto;
  margin-top: 3%;
}
.img-fluid-circuit {
  width: 360px;
  height: 400px;
  margin: 0px;
}
.img-fluid-flag {
  width: 360px;
  height: 200px;
  margin: 0px;
}
.table-raceweekend-events{
  width: 360px;
}

.div-single-race-details{
  margin-top: 1px;
  height:auto;

}

/*.tr-raceAndTrackInfo{
  width: 70%;
  margin-left: 30%;
  margin-top: 30%;

}*/
.h3-country{
  width:360px;
  height:auto;
  margin-top: 3%;
  margin-left: 3%;
}

.h3-race-divider{
  /*width: 70%;
  margin-left: 0%;*/
  margin-bottom: 4%;
  margin-top: 0%;

}

.h3-country-next{ 
  display: inline;
  
}
.span-country { 
  display: inline;
  background-color: aqua;
  font-size: large;
}

.race-event { 
  display: inline;
  font-size: large;
  font-weight: bolder;
}


/*
.rowC{
  display:flex;
  flex-direction:row;
  width:360px;
  height:auto;
}*/

/*.progress-bar{
  width:1300px;
  
}*/



.h2hcontainer{
  background-color: rgb(248, 247, 245);
  border-color: blue;
  border-width: 5%;
  
  /*margin-left: 200px;
  margin-right: 200px;
  margin-top: 30px;*/
  width: auto;
  max-width: 1000px;
  margin: auto;
  margin-top: 3%;
  padding-bottom: 8%;
}

.h2hcontainer-row{
  background-color: rgb(248, 247, 245);
  border-color: blue;
  border-width: 5%;
  /*margin-left: 200px;
  margin-right: 200px;
  margin-top: 30px;*/
  margin:auto;
}

.h2hcol{ 
  background-color: rgb(38, 102, 219);  
}

.h2hmetric{ 
  height: 20;
  margin: 0;
  
}

.img-fluid-h2hphoto{
  padding: 0;
  margin-right: 0%;
  background-color: rgb(245, 235, 234);
  width: 100%;
  height: 250px;
}

.div-h2hmetric{
  padding: 0;

}

.h6-driverLabel{
  color:rgb(119, 175, 66);
  font-size: 40px;
  font-weight: bolder;
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  text-align: center;
  text-justify: center;
  
  margin: auto;

}

.metricLabel{
  font-size: large;
  font-weight: bolder;
  text-align: center;
  margin-bottom: 0%;
  margin-top: 1%;
}


.metric-progress-bar{
  align-content:flex-end;
  font-size: x-large;
  font-weight: bolder;
  
}

.hr-divider{
  color: rgb(247, 74, 31);
  height: 0px;
  border-top: 1px solid black;
}

.div-divider{
  border: 1px solid black;
}

.admin-buttons-grid{
  background-color: rgb(248, 247, 245);
  border-color: blue;
  border-width: 0%;
  /*margin-left: 200px;
  margin-right: 200px;*/
  margin-top: 10%;
  margin-bottom: 15%;
  /*margin: 5%;*/
}

/*.admin-button-row{
  border-width: 50%;
  color: rgb(34, 11, 117);

}
*/
.admin-button{
  border-width: 10%;
  color: rgb(34, 11, 117);
  padding-top: 10%;

}

.changesButtonsSection{
  padding: 3%;
  border-style: dotted;
  border-color: rgb(194, 27, 69);
  margin-top: 2%;

}

.changesButton{
  margin-top: 3%;
  width: 200px
}

.raceheadingsection{
  padding: 2%;
  border-style: dotted;
  border-color: blue;
  margin-top: 3%;
  
}

.raceSelectionDropDown{
  width:150px;
  max-width:150px;
  background-color: blueviolet;
  height: 50px;
  max-height: 50px;
}

.raceHeadingCol{
  margin-left: 0%;
  color:brown
}

.dateAndTimeTextInput{
  width:300px;
  max-width:3500px;
  text-align: justify;

}

.nextRaceIndicator{
  margin-top: 10%;
  padding-top: 5%;
  width:150px;
  max-width:150px;
  background-color: aqua;
  height: 50px;
  max-height: 50px;  
}

.nextRaceIndicatorLabel{
  margin-top: 0%;
  margin-left: 8%;  
}

.eventDetailsSection{
  padding: 3%;
  border-style: dotted;
  border-color: rgb(111, 233, 12);
  margin-top: 5%;
}

.dayEventAndTimeInput{
  margin-top: 3%;
  width: 550px
}
.dayEventAndTimeLabel{
  
  width: 150px
}


/*AdminDataLoad.js*/

.pageoutlinecontainer{
  padding-top:3%;
  /*border-color: rgba(167, 162, 162, 0.425);
  border-style: solid;*/
  
}
.backToAdminButtonSection{
  align-items: left;
  padding-left: 0.5%;
}
/*.backToAdminButton{
  align-items: left;
  color: aliceblue;
  padding-left: 0%;
  max-height: 45px;
}*/


.pagetitlebarCol{
  background-color: rgb(238, 238, 247);
  text-align:center;
  height: 50px;
  padding-left: 10%;
  max-width: 97%;
  margin-left: 1.2%;
}
.outlineTitleText{
  font-size: x-large;
  font-weight: bold;
  line-height: 55px;
}
.containerVmControlSection{
  margin-left: 5%;
  padding-bottom: 5%;
  padding-top: 5%;
  /*border-color: black;
  border-style: dashed;*/
}
.sectionVmOnOff{  
  padding-top: 20%;
  padding-bottom: 20%;  
}
.switchVmOnOff{  
  zoom: 2.0;  
}
.labelVmOnOff{
  height: 25px;
  width: 70%;
  line-height: 55px;
}

.containerProgramRunControls{
  padding-left: 6%;
}
.programSelectDropDown{
  padding-bottom: 3%;  
}
.dropDownGenericYear{
  padding-bottom: 3%;
}
.buttonProgramRun{
  padding-bottom: 3%;
}
.dropDownGenericRound{
  padding-bottom: 3%;

}

.tableRunHistory{
  margin-left: 3%;
}

/*
.rowVmControlSection{
  
  
  
  padding-left: 2%; 
}

.rowProgramSelect{
  height: 75px;
  padding-top: 2%;
  padding-bottom: 15%;
  padding-left: 2%;  
  
}




.containerProgramRunButtone{
  
  padding-left: 1%;
  max-height: 3px;  
}
  */

.ButtonRunProgram{
  max-height: 40px;
}
.rowProgramSelectRun{
  padding-top: 5%;
  padding-bottom: 0%; 
}
.selectYearRound {
  margin-left: 3%;
  padding-top: 2%;
  padding-left: 0%;
  
}

.divDStandingsEditConfirmSection{
  padding-top: 2%;
  justify-content: center;
}




